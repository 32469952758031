// ==== grid === //
body, .wrapper, header.site-header, .site-title, .site-nav, .site-nav ul,  main, footer, section{
	display:flex;
}
body{
	flex-direction: column;
}
header.site-header, main,footer{	
	width:100vw;
}
header.site-header,footer{
	justify-content: center;
}
.wrapper{
	margin:0 auto;
	justify-content: center;
}
header.site-header{
	margin:0.5rem 0 0 0;
	.wrapper{	
		padding:1rem 0;
		align-items: center;
		.site-title{
			width:100vw;
			background-image: url('../images/ident.png'); 
			background-repeat:no-repeat;
			background-size:contain;
			span{
				@extend %h-e;
			}
		}
		.site-nav{
			ul{
				flex-direction: row;
				li{
					justify-content: flex-start;
					padding:0 0 0 1rem;
				}
			}
		}
	}
}	
main{
	display: flex;
	justify-content: center;
	flex-direction: column;
	.wrapper{
		flex-direction: column;
	}
	section{
		width:100%;
		flex-direction: row;
		.content{
			padding:1rem;
		}	
		> div{
			margin:1rem 0;
		}
	}
}
.intro{
	padding:2rem 0;
	margin:0 0 1rem 0;
	border-top:1px solid #999;
	@extend %border_bottom;
}
.afterwards-contact{
	text-align: center;
	@extend %border_bottom;
	padding:0 0 2rem 0;
}
.promos{
	width:100%;
	padding:1rem;
}
.aside{
	position:relative;
	.promo_boxes{
		li{
			margin:0 0 2rem 0;
		}
	}
}

.promos{
	.promo_boxes{
		display: flex;
		flex-direction: row;
		justify-content: space-between;	
		
		li{
			img{
				max-width: 100%;
				width:100%;	
				height: auto;
			}
		}
	}
}

.just-list{
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
	margin:0 0 2rem 0;
	article {
		position: relative;
		overflow:hidden;
		height:380px;
		div{
			position: absolute;
			z-index:1;
			bottom:0;
			background: rgba(white, 0.65);   
			padding:1rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		p{
			margin-bottom: 1.75rem;
		}
	}
}

footer{
	padding:1rem;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width:25%;
  margin-top: 0px; 
  margin-bottom: 0px;
}
/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .promo3 {
  padding-top: 102px;
}

//================= min-width 320 ================//

@include respond-to(mobile, true) { 
	.wrapper{
		max-width:90%;
	}
	header.site-header{
		.wrapper{
			flex-direction: column;
			justify-content:center;
		}
		.site-title, .site-nav{
			display: flex;
		}
		.site-title{
			height:1.5rem;
			background-position: center top;
		}
	}
	
	main{
		section{
			flex-direction: column;
		}
	}
	section {
		.content{
			width:100%;
		}
		.aside{
			width:100%;
		}
	}
	.just-list{
		flex-direction: column;
		flex-wrap: nowrap;
		article{
			width:100%;
			margin:0 0 1rem 0;			
		}
	}
}




// ============ min-width 720 =================//

@include respond-to(tablet, true) { 
	.wrapper{
		max-width:45rem;
	}
	header.site-header{
		.wrapper{
			flex-direction: row;
			width:100%;
			height:6rem;
		}
		.site-title{
			height:3rem;
		}

		.site-nav{
			width:60%;
			justify-content: flex-end;
			align-items: flex-end;
			ul{
				justify-content:flex-end;
				flex-wrap: nowrap;
			}
		}	
	}
	.just-list{
		flex-direction: row;
		flex-wrap: wrap;
		article{
			width:50%;
			margin:0 0 1rem 0;
			height:450px;
			img{
				width:auto;
				height: 100%;
			}
			div{
				//height: 270px;
			}
		}
	}
}



// ================== min-width 1280 ====================//

@include respond-to(largest_width, true) { 
	.wrapper{
		max-width:45rem;
	}
	header.site-header{
		.wrapper{
			height:9rem;
		}
	}
	
}








