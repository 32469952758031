// ==== colours === //

$body-color: #ffffff;
$text-color:#313131;
$link-color:#212121;
$ident-color:#91398D;

$pantone_coty:#FA7268;


// === raspberry tones === //

$turq: #a1c3d1;
$mauve: #b39bc8; 
$off_white: #f0ebf4;
$pink: #f172a1;
$raspberry: #e64398;
$dk_raspberry:#a71663;

// == section colours == //

$section_one:$dk_raspberry;
$section_one_text:$off_white;
$section_two:$turq;
$section_two_text:$text-color;

%colour_default{
	background:$off_white;
	colour:$text-color;
	a, a:link{
		colour:$text-color;
	}	
}
%colour_one{
	color:$section_one_text;
	background:$section_one;
	a, a:link{
		colour:$section_one_text;
	}
}

%colour_two{
	color:section_two_text;
	background:$section_two;
	a, a:link{
		colour:$section_two_text;
	}
}
%border_bottom{
	border-bottom:1px solid lighten($ident-color, 60%);
}
button, .button {
	border:0;
	color: white!important;
	background-color: $text-color;
	padding: 0.5rem 1rem;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	&:hover {
		background-color: $dk_raspberry;
		color: white;
	}
	&:active {
		background-color: black;
		color: white;
		transform: translateY(4px);
	}
	/*&:after{
		content: ' \003E';
		padding:0 0 0 1rem;
	}*/
}
footer{
	background:$ident-color;
	color:white;
	a, a:link{
		color:white;
	}
}
