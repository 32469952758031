// ==== structure ==== //


// background images for projects
.just-list{
	article{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left center;
	}
}

.fire{
	background-image: url('../img/actasif.jpg');
}
.london{
	background-image: url('../img/london.jpg');
}
.misfortune{
	background-image: url('../img/misfortune.jpg');
}
.synesthesia{
	background-image: url('../img/synesthesia.jpg');
}
.dontlet{
	background-image: url('../img/dontlet.jpg');
}
.almanac{
	background-image: url('../img/almanac.png');
}
.eda{
	background-image: url('../img/edascheurer.png');
}
.shiftms{
	background-image: url('../img/shift.png');
}
.return{
	background-image: url('../img/return.png');
}
.apocalypse{
	background-image: url('../img/apocalypse.png');
}



// === blog related === //

.post-list{
	li{
		margin:0 0 1rem 0;
	}	
}

ul.index{
	li{
		margin:0 0 2rem 0;
	}
}