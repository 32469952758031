// ==== resets === // 
*, html {
  box-sizing: border-box;
}

body, /*h1, h2, h3,*/ h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0; }
h1,h2,h3{
	margin:0 0 1em 0;
}
h1, h2, h3, h4, h5, h6, button, .button{
  font-weight:400;
}
ul, ol, dl, li{
  list-style-type: none;
}
/**
 * Basic styling
 */

body {
  
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  }