// ==== tyopgraphy ==== //
$rem-base-font: 16px !default;
@mixin font-rem($font-size, $line-height: 0) {
	font-size: $font-size;
	font-size: ($font-size / $rem-base-font) * 1rem;
	@if ($line-height > 0) {
		line-height: $line-height;
		line-height: ($line-height / $rem-base-font) * 1rem;
  }
}

$heading-font:'Secular One', sans-serif;
$body-font: 'Montserrat', sans-serif;

body{
	font-family: $body-font;
	line-height: 1.5em;
	direction: ltr;
	-webkit-font-variant-ligatures: no-common-ligatures;
	font-variant-ligatures: no-common-ligatures;
	-webkit-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
	-moz-font-feature-settings: "kern" 1;
	-o-font-feature-settings: "kern" 1;
	font-feature-settings: "kern" 1;
	font-kerning: normal;
}

%uppercase{
	text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6{	
	letter-spacing: 0.025rem;
	font-family:$heading-font;
}

h1{
	@include font-rem(24px, 32px);
	padding:1rem 0;
}
h2{
	@include font-rem(21px, 28px);
	margin:0 0 1rem 0;
	text-align: center;
}
h3{
	@include font-rem(16px, 18px);
}
.intro{
	padding:1rem 0;
	p{
		margin:0 0 1rem 0;
	}
}
main{
	article{
		h3{
			margin:0 0 0.5rem 0;
		}
		p{
			@include font-rem(18px, 26px);
			margin:0 0 1rem 0;
		}
	}
}
figure{
	@include font-rem(10px, 12px);
	position:absolute;
	bottom:1rem;
}

.post-list{
	h3{
		@extend %uppercase;
	}
}
.post-meta{
	@include font-rem(14px, 18px);
}

#intro_text{
	@include font-rem(21px, 32px);
	p{
		margin:0 0 1rem 0;
	}
}
.aside{
	.promo_boxes{
		li{
			h3{
				@extend %uppercase;
				font-weight: 600;
			}
		}
	}
}
.disclosure{
	h4{
		font-weight:600;
	}
	@include font-rem(14px, 21px);
	padding:1rem;
}

.site-footer{
	ul, p, address{
		@include font-rem(14px, 21px);
		margin:0 0 0.25rem 0;
	}
}

.just-list{
	article{
		h3{

		}
		p{
			@include font-rem(14px, 21px);
		}
	}
}
button, .button {
	@include font-rem(18px, 21px);
	@extend %uppercase;
	letter-spacing: 0.05rem;
}

//================= min-width 320 ================//

@include respond-to(mobile, true) {
.intro{
	@include font-rem(18px, 24px);
}	
	.aside{
		.promo_boxes{
			li{
				h3{
					margin:0 0 1rem 0;
				}
				p{
					margin:0 0 1rem 0;
				}
			}
		}
	}
}
// ============ min-width 720 =================//

@include respond-to(agnostic, true) { 
	h1{
		@include font-rem(24px, 32px);
		padding:0 0 2rem 0;
	}
	h2{
		@include font-rem(21px, 24px);
		margin:0 0 1.25rem 0;
	}
	h3{
		@include font-rem(18px, 21px);
	}
	#intro_text{
		@include font-rem(21px, 32px);
		padding:0 0 1.5rem 0;
	}
	main{
		article{
			p{
				@include font-rem(18px, 21px);
				margin:0 0 1rem 0;
			}
		}
	}
}

// ================== min-width 1280 ====================//

@include respond-to(largest_width, true) { 
	h1{
		@include font-rem(32px, 36px);
		padding:0 0 2.5rem 0;
	}
	h2{
		@include font-rem(24px, 32px);
		margin:0 0 1.5rem 0;
	}
	h3{
		@include font-rem(18px, 24px);
	}
	#intro_text{
		@include font-rem(24px, 32px);
		padding:0 0 1.75rem 0;
	}
	main{
		article{
			p{
				@include font-rem(21px, 28px);
				margin:0 0 1rem 0;
			}
		}
	}
}